@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

/* TRUNCATE */

.truncated {
  display: flex;
  flex: 1;
  min-width: 0; /* or some value */
  /* color: #fff; */
}

.truncated div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}
